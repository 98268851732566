.whitelist {
    padding: 0 16px;
}

.headline {
    font-family: ABBvoice;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    color: #262626;
    margin-top: 60px;
    &::before{
        content: "—";
        color: #ff000f;
        display: block;
    }
}

.leadText {
    font-family: ABBvoice;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #262626;
    max-width: 520px;
}

.buttonContainer {    
    width: 100%;
    max-width: 520px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.actionButton {
    background-color: white;
    border: none;
    cursor: pointer;
    font-family: ABBvoice;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 0 10px 0 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    &.cancel {
        color: #6E6E6E;
    }
    &.save, &.migrate {
        color: #FF000F;
    }
}

.linkButtonContainer {   
    width: 100%;
    max-width: 520px; 
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.linkButton {
    color: #ffffff;
    clear: both;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-radius: 43px;
    background: rgb(255, 0, 15);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0px 24px;
    border: 0px;
    cursor: pointer;
    min-width: 100px;
    transition: all 0.25s;
    margin: 0px auto;
    text-align: center;
}
