.header {
    height: 63px;
    padding: 0 16px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}

.logo {
    background-size: 76px 29px;
    width: 76px;
    height: 29px;
    margin-left: 9px;
}

.app-name{
    margin-left: 20px;
    font-weight: 700;
}
