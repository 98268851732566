.textarea {
    background: #F5F5F5;
    border: none;
    width: 100%;
    max-width: 520px;
    height: 305px;
    overflow-x: auto;

    p {
        height: 22px;      
        font-family: ABBvoice;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #262626;
        padding: 4px 0 4px 8px;
        margin: 0;
        &:last-child {
            padding-bottom: 16px;
        }

        &.placeholder {
            font-family: ABBvoice;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 28px;  
            color: #6E6E6E;
            padding-bottom: 0;
        }
    }
}

.redBottomBorder{
    border-bottom: 1px solid #ff000f;
}

.errorMessage {
    color: #ff000f;
    display: block;
    font-family: ABBvoice, Verdana, Arial, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-transform: inherit;
    line-height: normal;
    padding: 16px 0 0 8px;
}