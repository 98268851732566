.button {
    color: #ffffff;
    clear: both;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-radius: 43px;
    background: rgb(255, 0, 15);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0px 24px;
    border: 0px;
    cursor: pointer;
    min-width: 150px;
    transition: all 0.25s;
    margin: 0px auto;
    text-align: center;
    
    &:disabled {
        cursor: not-allowed;
        background-color: rgb(189, 189, 189);
    }
}
