.loading {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(255, 254, 254, 0.8), rgba(255, 255, 255, 0.8));
    background: -webkit-radial-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url(./abb-loader.gif);
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: 999999;
    }
}
