$error: #ff000f;
$primary-font: ABBvoice;
$primary-font-family: $primary-font,
Verdana,
Arial,
sans-serif;
$mdc-theme-primary: #6e6e6e;
$mdc-text-field-helper-text-color: #6e6e6e;
$mdc-text-field-error: $error;
$mdc-typography-font-family: $primary-font-family;

@import '~@material/react-text-field/index.scss';

@font-face {
    font-family: $primary-font;
    src: local($primary-font), url(./assets/fonts/ABBvoice_W_Rg.woff) format('woff');
}

html, body{
    height: 85%;
}

body,
input {
    color: #262626;
    font-family: $primary-font-family;
}

.link {
    color: inherit;
    border-bottom: 2px solid #d2d2d2;
    text-decoration: none;
    transition: border-bottom .25s;

    &:hover {
        border-color: $error;
    }
}

#root{
    height: 100%;
}

.container {
    padding-bottom: 20px;
    width: 100%;
    max-width: 520px;

    &.text-field div {
        width: 100%;
    }
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.mdc-text-field-helper-text--validation-msg {
    text-align: left;

    & a {
        color: $error;
    }
}

@media (max-width: 520px) { 
    .content {
        height: auto;
    }
 }